import { ThemingService } from './../../../services/theming.service';
import { Theme } from './../../../models/theme.model';
import { MpgClinicalstudiesComponent } from './mpg-clinicalstudies/mpg-clinicalstudies.component';
import { MobileFaqComponent } from './mobile-faq/mobile-faq.component';
import { FooterService } from './../../../services/footer.service';
import { Footer } from './../../../models/footer.model';
import { RepeatElements } from './../../../models/repeat.model';
import { element } from 'protractor';
import { DynamicComponent } from './../../shared/dynamic/dynamic.component';
import { RenderContainerElement, ElementAttributes } from './../../../models/render.model';
import { Component, OnInit } from '@angular/core';
import { MpgIngredientsComponent } from './mpg-ingredients/mpg-ingredients.component';

@Component({
  selector: 'app-memoplusgold',
  templateUrl: './memoplusgold.component.html',
  styleUrls: ['./memoplusgold.component.css']
})
export class MemoplusgoldComponent implements OnInit {

  footer: Footer = <Footer>{
    facebookURL: 'memoplusgold.facebook',
    instagramURL: 'memoplusgold.ig',
    twitterURL: 'memoplusgold.twitter',
    lazadaURL: 'memoplusgold.lazada',
    shopeeURL: 'memoplusgold.shopee',
    youtubeURL: 'memoplusgold.youtube',
    product: 'mpg'
  };

  theme: Theme = <Theme> {
    navbarColor: '',
    navbarFontColor: 'white',
    footerColor: '#b1d143',
    footerFontColor: 'white',
    sidePanelColor: '',
    sidePanelFontColor: '',
    navbarLogoURL: '/assets/products/memoplusgold/particle.png'
  };

  shop;

  aboutSection = <RenderContainerElement>{
    elementComponentType: DynamicComponent,
    elementName: 'about-mpg',
    elementTag: 'div',
    isGrid: true,
    elementClass: ['container','py-5','mt-5'],
    childElements: [
      <RenderContainerElement>{
        elementComponentType: DynamicComponent,
        elementName: 'about',
        elementTag: 'div',
        elementClass: ['uk-grid','uk-child-width-1-2@m','py-5'],
        isGrid: true,
        childElements: [
          <RenderContainerElement>{
            elementTag: 'div',
            elementClass: ['pt-3'],
            childElements: [
              <RenderContainerElement>{
                elementTag: 'img',
                elementClass: ['w-100'],
                elementAttribute: {
                  src: '/assets/products/memoplusgold/kim.png'
                }
              }
            ]
          },
          <RenderContainerElement>{
            elementTag: 'div',
            elementClass: ['m-auto'],
            childElements: [
              <RenderContainerElement>{
                elementTag: 'h2',
                elementClass: ['_green-header'],
                elementAttribute: {
                  text: 'The brain vitamin for GOLD achievers'
                }
              },
              <RenderContainerElement>{
                elementTag: 'p',
                elementClass: ['content-text', 'uk-text-justify'],
                elementAttribute: {
                  text:
                    'Memo Plus Gold is an all-natural supplement made from the extract of pure and natural Bacopa monniera that helps enhance brain functions so that you can always be BrainyGood.'
                }
              },
              <RenderContainerElement>{
                elementTag: 'p',
                elementClass: ['content-text', 'uk-text-justify'],
                elementAttribute: {
                  text:
                    'It is developed and patented by the Central Drug Research Institute (CDRI) in India and tested at the Brain Science Institute in Australia.'
                }
              },
              <RenderContainerElement>{
                elementTag: 'h2',
                elementClass: ['content-text', '_green-header'],
                childElements: [
                  <RenderContainerElement>{
                    elementTag: 'i',
                    elementClass: ['fas', 'fa-arrow-circle-right', 'pr-1'],
                    childElements: []
                  },
                  <RenderContainerElement>{
                    elementTag: 'a',
                    elementClass: ['content-text'],
                    elementAttribute: {
                      href: '/#/products/tips/mpg',
                      text: 'See Health Bits Here!'
                    }
                  }
                ]
              },
            ]
          },
        ]
      },
    ]
  };

  ingredientsSection = <RenderContainerElement>{
    elementComponentType: DynamicComponent,
    elementName: 'ingredient',
    elementTag: 'div',
    isGrid: false,
    elementClass: ['w-100','uk-inline','pt-4'],
    childElements: [
      <RenderContainerElement>{
        elementComponentType: MpgIngredientsComponent,
        elementTag: 'div',
        elementClass: [],
      }
    ]
  };

  studies: RepeatElements = new RepeatElements({
    parentTag: 'div',
    tag: 'div',
    isGrid: false,
    elements: [
      'The Central Drug Research Institute (CDRI), a premier drug research laboratory in Lucknow, India, started researching and analysing the Brahmi plant.',
      'Aithal and Sirsi found that the alcoholic extract, and to a lesser extent the aqueous extract of the whole plant exhibited tranquilizing effects on albino rats and dogs. Prakash and Sirsi have found that the alcoholic extract of the plant and chlorpromazine improved the performance of rats in motor learning.',
      'Two active memory-enhancing molecules were isolated and identifies as Bacosides A and B. Together, they provide memory enhancement that is more effective than that provided by Ginkgo Biloba.',
      'Sinha has reported that a single dose of the glycoside hersaponin is better than peritobarbitone in facilitating acquisition and retention of brightness discrimination reaction.',
      'The evaluation of the traditional claims of Brahmi was initiated by investigating its effect on the acquisition, consolidation and retention of three newly acquired behavioral responses in albino rats. (Singh and Dhawan)',
      'The safety and tolerability of bacosides in 51 healthy human volunteers were assessed. It was found that single oral doses of 20-200 mg or 100 and 200 mg once daily for 4 weeks were safe and did not produce any reaction or side effect.',
      'Findings published in Psychopharmacology (2001) 156: 481-484 showed test results that Brahmi significantly improved visual information processing. This is based on studies conducted at the Swinburne University in Australia.',
      'Results published in Neuropsychopharmacology clearly showed a significant effect on the retention of new information, the speed of information processing, verbal learning rate and memory consolidation. The study is based on tests conducted at the University of Wollongong in Australia.'
    ],
    parentClass: [],
    childrenClass: ['py-2']
  });

  clinicalStudiesSection = <RenderContainerElement> {
    elementTag: 'div',
    elementComponentType: DynamicComponent,
    elementName: 'clinicalStudies',
    elementClass: ['uk-hidden@m','container','py-5'],
    isGrid: false,
    childElements: [
      <RenderContainerElement>{
        elementTag: 'h3',
        elementClass: ['uk-text-center','_green-header'],
        elementAttribute: <ElementAttributes>{
          text: 'Clinical Studies'
        }
      },
      <RenderContainerElement>{
        elementTag: 'div',
        elementClass: ['m-auto','px-3'],
        childElements: [
          <RenderContainerElement>{
            elementTag: 'img',
            elementClass: ['w-100'],
            elementAttribute: {
              src: '/assets/products/memoplusgold/capsule.png'
            }
          },
          this.studies._parent
        ]
      },
      <RenderContainerElement>{
        elementTag: 'div',
        elementClass: ['uk-text-center','broken-lines','py-5','my-5','mx-auto'],
        childElements: [
          <RenderContainerElement>{
            elementTag: 'h4',
            elementClass: ['mb-0'],
            childElements: [
              <RenderContainerElement>{
                elementTag: 'a',
                elementClass: ['content-text'],
                elementAttribute: {
                  href: '/assets/products/memoplusgold/clinicalstudy.pdf',
                  text: 'Click Here to read the complete details.'
                }
              }
            ]
          }
        ]
      }
    ]
  };

  faq = new RenderContainerElement({
    elementTag: 'div',
    elementComponentType: MobileFaqComponent,
    elementName: 'faq',
    elementClass: [],
    isGrid: false
  });

  nuclinical = <RenderContainerElement> {
    elementComponentType: MpgClinicalstudiesComponent,
    elementName: 'ingredient',
    elementTag: 'div',
    isGrid: false
  };

  displayElements: any[] = [
    this.aboutSection,
    this.ingredientsSection,
    // this.clinicalStudiesSection,
    this.nuclinical,
    this.faq
  ];

  constructor(
    private fos: FooterService,
    private ths: ThemingService) {
    this.fos.footerData = this.footer;
    this.ths.SetThemeValue = this.theme;
  }

  ngOnInit() {
    this.shop  = {
        mpg: {
        imgURL: './assets/products/shop/shop_cdb.png',
        shopeeURL: 'https://shopee.ph/winlodale',
        lazadaURL: 'https://www.lazada.com.ph/shop/memo-plus-gold-official-store'
      },
      arthrid: {
        imgURL: './assets/products/shop/shop_arthrid.png',
        shopeeURL: 'https://shopee.ph/arthridofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/arthrid-official-store'
      },
      cdb: {
        imgURL: './assets/products/shop/shop_mpg.png',
        shopeeURL: 'https://shopee.ph/cogentofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/cogent-db-official-store'
      }
    };
  }

}
