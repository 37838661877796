import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mpg-ingredients',
  templateUrl: './mpg-ingredients.component.html',
  styleUrls: ['./mpg-ingredients.component.css']
})
export class MpgIngredientsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
