import { Switcher } from './../../../models/switcher.model';
import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef,
  AfterContentInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css', '../shared.component.css']
})
export class SwitcherComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild('bind', { read: ElementRef }) private bind: ElementRef;
  @Input() dataSource: any;

  bindTab: any;
  ngAfterContentInit(): void {

  }

  ngAfterViewInit(): void {

  }

  constructor(private renderer: Renderer2,
    private el: ElementRef) {

  }

  ngOnInit() {
    // console.log(this.dataSource.childElements[0]);
    this.bindTab = 'uk-tab="connect: #component-' + this.dataSource.childElements[0].placeholderInput + '; animation: uk-animation-fade"';
    this.renderer.setAttribute(this.bind.nativeElement, 'uk-tab',
    'connect: #component-' + this.dataSource.childElements[0].placeholderInput + '; animation: uk-animation-fade');
  }
}
