import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogent-diabetes',
  templateUrl: './cogent-diabetes.component.html',
  styleUrls: ['../cogentdb.component.css', './cogent-diabetes.component.css']
})
export class CogentDiabetesComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
