import { RenderContainerElement } from './render.model';
export class RepeatElements {

  public _parent?: RenderContainerElement;
  public tag: string;
  public parentTag: string;
  private _isGrid?: boolean;
  public elements: any[];
  public parentClass: string[];
  public childrenClass: string[];
  public customLayout?: RenderContainerElement;

  get isGrid() {
    return this._isGrid;
  }

  set isGrid(value) {
    this._isGrid = value;

    this._isGrid === true ? this.isThisGrid() : this.tag = this.tag;

  }

  private isThisGrid? = () => {
    this.tag = 'div';
  };

  private createChildren? = (element, tag, styles) => {
    this._parent.childElements.push(new RenderContainerElement({
      elementName: '',
      isGrid: false,
      elementTag: tag,
      childElements: [],
      elementClass: styles,
      elementAttribute: {
        text: element
      }
    }));
  }

  private createParent? = (tag: any, styles: any[]) => {
    this._parent = new RenderContainerElement({
      elementName: 'repeater',
      isGrid: this.isGrid,
      elementTag: tag,
      childElements: [],
      elementClass: styles
    });
  }

  constructor(ent?: RepeatElements) {
    if(ent) {
      this.createParent(
        ent.customLayout ? ent.customLayout.elementTag : ent.parentTag,
        ent.customLayout ? ent.customLayout.elementClass : ent.parentClass);

      ent.elements.forEach(element => {
        this.createChildren(
          element,
          ent.customLayout ? ent.customLayout.childElements[0].elementTag : ent.tag,
          ent.customLayout ? ent.customLayout.childElements[0].elementClass :ent.childrenClass);
      });
      this.isGrid = ent.isGrid;
    }
  };
}
