import { CardComponent } from './../components/shared/card/card.component';
import { DynamicComponent } from './../components/shared/dynamic/dynamic.component';
import { ElementAttributes } from './../models/render.model';
import { Injectable, ViewContainerRef, Renderer2, ElementRef, Injector, ApplicationRef, ComponentFactoryResolver } from '@angular/core';
import { RenderContainerElement } from '../models/render.model';
import { NgSwitchCase } from '../../../node_modules/@angular/common';

@Injectable()
export class DynamicService {
  nativeElement: ElementRef;
  renderer: Renderer2;
  applicationRef: ApplicationRef;

  constructor(private injector: Injector, private resolver: ComponentFactoryResolver) {}

  setNativeElement(native) {
    this.nativeElement = native;
  }

  setParentEL(obj: RenderContainerElement) {
    const el = this.renderer.createElement(obj.elementTag);
    this.renderer.setAttribute(el, 'id', obj.elementName);

    if (obj.elementClass) {
      this.appendClasstoElement(el, obj.elementClass);
    }

    if (obj.childElements) {
      this.createChildrenContainers(obj.childElements, el);
    }

    this.renderer.appendChild(this.nativeElement.nativeElement, el);
  }
  assignChildenToParentEL(chidlren: any[], elRef) {}

   /**
   * Assign an Attribute to a created Element
   * @param el Current Element
   * @param attributes Available Attributes for the Element
   */
  assignAttributeToElement(el, attributes: ElementAttributes) {
    if (attributes['src'] !== undefined) {
      this.renderer.setAttribute( el, 'src', attributes.src );
    } else if (attributes['href'] !== undefined) {
      this.renderer.setAttribute(el,'href', attributes.href );
      // this.renderer.setAttribute(el,'target', "_blank" );
      const text = this.renderer.createText(  attributes.text );
      this.renderer.appendChild( el, text );
    }else if (attributes['text'] !== undefined) {
      const text = this.renderer.createText(  attributes.text );
      this.renderer.appendChild( el, text );
    }
  }

  createChildrenContainers(children: RenderContainerElement[], el: any) {
    children.forEach(element => {
      // Get the Parent end Children Elements
      const componentType =  element.elementComponentType ?  element.elementComponentType
        : DynamicComponent;

      const componentChildren = element.childElements ? element.childElements
        : [];

      const newEl = componentType !== DynamicComponent ? this.renderer.createElement('div')
        : this.renderer.createElement(element.elementTag);
      newEl.id = element.elementName;

      this.appendClasstoElement(newEl, element.elementClass);
      this.renderer.appendChild(el, newEl);

      if (componentType !== DynamicComponent) {

        const factory = this.resolver.resolveComponentFactory(componentType);
        const ref = factory.create(this.injector, [], newEl);

        switch(componentType) {
          case CardComponent: {

            ref.instance['dataSource'] = componentChildren;
            ref.changeDetectorRef.detectChanges();
          }
        }

        this.applicationRef.attachView(ref.hostView);

      } else if (componentChildren.length !== 0) {

        this.createChildrenContainers(componentChildren, newEl);

      } else if (element.elementAttribute){

        this.assignAttributeToElement(newEl, element.elementAttribute);

      }
    });
  }

  /**
   * Append a CSS Class to the Element
   * @param el Current element being accessed
   * @param styles List of Styles to be used by the Current element
   */
  appendClasstoElement(el, styles) {
    styles.forEach(style => {
      this.renderer.addClass(el, style);
    });
  }
}
