import { Injectable } from "../../../node_modules/@angular/core";
import { Theme } from "../models/theme.model";
import { BehaviorSubject } from "../../../node_modules/rxjs";

@Injectable()
export class ThemingService {

  private theme: Theme;
  private themeSubject: BehaviorSubject<Theme>;

  constructor() {
    this.themeSubject = new BehaviorSubject(this.theme);
  }

  get GetThemeValue() {
    return this.themeSubject.asObservable();
  }

  set SetThemeValue(value: Theme) {
    this.theme = value;
    this.updateSubject();
  }

  private updateSubject() {
    this.themeSubject.next(this.theme);
  }
}
