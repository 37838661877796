import { CogentAboutComponent } from './cogent-about/cogent-about.component';
import { ThemingService } from './../../../services/theming.service';
import { Theme } from './../../../models/theme.model';
import { FooterService } from './../../../services/footer.service';
import { Footer } from './../../../models/footer.model';
import { CogentDiabetes2Component } from './cogent-diabetes2/cogent-diabetes2.component';
import { CogentClinicalStudiesComponent } from './cogent-clinical-studies/cogent-clinical-studies.component';
import { CogentDiabetesComponent } from './cogent-diabetes/cogent-diabetes.component';
import { RepeatElements } from './../../../models/repeat.model';
import { DynamicComponent } from './../../shared/dynamic/dynamic.component';
import { RenderContainerElement } from './../../../models/render.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogentdb',
  templateUrl: './cogentdb.component.html',
  styleUrls: ['./cogentdb.component.css']
})
export class CogentdbComponent implements OnInit {

  shop;

  footer: Footer = <Footer>{
    facebookURL: 'cogentdb.facebook',
    instagramURL: 'cogentdb.ig',
    twitterURL: 'cogentdb.twitter',
    lazadaURL: 'cogentdb.lazada',
    shopeeURL: 'cogentdb.shopee',
    youtubeURL: 'cogentdb.youtube',
    product: 'cogent'
  };

  theme: Theme = <Theme> {
    navbarColor: '',
    navbarFontColor: 'white',
    footerColor: '#8cc445',
    footerFontColor: 'white',
    sidePanelColor: '',
    sidePanelFontColor: ''
  }

  aboutSection: RenderContainerElement = new RenderContainerElement({
    elementName: 'about',
    elementTag: 'div',
    elementClass: ['container'],
    elementComponentType: DynamicComponent,
    isGrid: false,
    childElements: [
      new RenderContainerElement({
        elementComponentType: CogentAboutComponent,
        elementName: 'about',
        elementTag: 'div',
        elementClass: [],
        isGrid: false,
      }),
    ]
  });

  ingredients: RenderContainerElement = new RenderContainerElement({
    elementName: 'ing-comp',
    elementTag: 'div',
    elementClass: ['py-5'],
    elementComponentType: DynamicComponent,
    isGrid: false,
    childElements: [
      <RenderContainerElement>{
        elementTag: 'div',
        elementClass: ['container'],
        elementComponentType: DynamicComponent,
        isGrid: false,
        childElements:[
          <RenderContainerElement>{
            elementTag: 'h1',
            isGrid: false,
            elementClass: ['uk-text-center','py-5', '_darkgreen-header'],
            elementAttribute: {
              text: 'INGREDIENTS'
            }
          },
          <RenderContainerElement>{
        elementTag: 'div',
        isGrid: false,
        elementClass: ['uk-width-xxlarge','uk-margin-auto','py-lg-3'],
        childElements: [
          <RenderContainerElement>{
            elementTag: 'img',
            isGrid:false,
            elementClass: ['w-100','uk-visible@m'],
            elementAttribute: {
              src: '/assets/products/cogent/ing.png'
            }
          },
          <RenderContainerElement>{
            elementTag: 'img',
            isGrid:false,
            elementClass: ['w-100','uk-hidden@m'],
            elementAttribute: {
              src: '/assets/products/cogent/ing.gif'
            }
          }
        ]
          },
        ]
      },
    ]
  });

  clinicalStudies: RenderContainerElement = new RenderContainerElement({
    elementName: 'clinicalStudies',
    elementTag: 'div',
    elementClass: [],
    elementComponentType: DynamicComponent,
    isGrid: false,
    childElements: [
      new RenderContainerElement({
        elementComponentType: CogentClinicalStudiesComponent,
        elementName: 'about',
        elementTag: 'div',
        elementClass: [],
        isGrid: false,
      }),
    ]
  });

  whatIsDiabetes: RenderContainerElement = new RenderContainerElement({
    elementName: 'clinicalStudies',
    elementTag: 'div',
    elementClass: [],
    elementComponentType: DynamicComponent,
    isGrid: false,
    childElements: [
      new RenderContainerElement({
        elementComponentType: CogentDiabetesComponent,
        elementName: 'about',
        elementTag: 'h3',
        elementClass: ['text-upper'],
        isGrid: false,
        elementAttribute: {
          text: 'DIABETES'
        }
      }),
      new RenderContainerElement({
        elementComponentType: CogentDiabetes2Component,
        elementName: 'about',
        elementTag: 'h3',
        elementClass: ['text-upper'],
        isGrid: false,
        elementAttribute: {
          text: 'DIABETES'
        }
      }),
    ]
  });

  displayElements: any[] = [
    this.aboutSection,
    this.ingredients,
    this.whatIsDiabetes,
    this.clinicalStudies,
  ];

  constructor(private fos: FooterService,
    private ths: ThemingService) {
    this.fos.footerData = this.footer;
    this.ths.SetThemeValue = this.theme;
  }

  ngOnInit() {
    this.shop  = {
        mpg: {
        imgURL: './assets/products/shop/shop_cdb.png',
        shopeeURL: 'https://shopee.ph/winlodale',
        lazadaURL: 'https://www.lazada.com.ph/shop/memo-plus-gold-official-store'
      },
      arthrid: {
        imgURL: './assets/products/shop/shop_arthrid.png',
        shopeeURL: 'https://shopee.ph/arthridofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/arthrid-official-store'
      },
      cdb: {
        imgURL: './assets/products/shop/shop_mpg.png',
        shopeeURL: 'https://shopee.ph/cogentofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/cogent-db-official-store'
      }
    };
  }

}
