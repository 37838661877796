import { ThemingService } from './../../services/theming.service';
import { FooterService } from './../../services/footer.service';
import { Theme } from './../../models/theme.model';
import { Footer } from './../../models/footer.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  public isCdb: boolean;
  public isArthrid: boolean;
  public isMpg: boolean;

  footer: Footer = <Footer>{
    facebookURL: 'arthrid.facebook',
    instagramURL: 'arthrid.ig',
    twitterURL: 'arthrid.twitter',
    lazadaURL: 'arthrid.lazada',
    shopeeURL: 'arthrid.shopee',
    youtubeURL: 'arthrid.youtube',
    product: 'arthrid'
  };

  theme: Theme = <Theme> {
    navbarColor: '',
    navbarFontColor: 'white',
    footerColor: '#8cc63f',
    footerFontColor: 'white',
    sidePanelColor: '',
    sidePanelFontColor: ''
  }

  constructor(
    private fos: FooterService,
    private ths: ThemingService) {
    this.fos.footerData = this.footer;
    this.ths.SetThemeValue = this.theme;

    // console.log(this.theme);
  }

  ngOnInit() {
    this.isArthrid = true;
    this.isCdb = true;
    this.isMpg = true;
  }

  all() {
    this.isArthrid = true;
    this.isCdb = true;
    this.isMpg = true;
  }
  mpg() {
    this.isArthrid = false;
    this.isCdb = false;
    this.isMpg = true;
  }

  arthrid() {
    this.isArthrid = true;
    this.isCdb = false;
    this.isMpg = false;
  }

  cdb() {
    this.isArthrid = false;
    this.isCdb = true;
    this.isMpg = false;
  }

}
