import { TipsModule } from './../tips/tips.module';
import { CogentdbComponent } from './cogentdb/cogentdb.component';
import { MemoplusgoldComponent } from './memoplusgold/memoplusgold.component';
import { ArthridComponent } from './arthrid/arthrid.component';
import { ProductsComponent } from './products.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '../../../../node_modules/@angular/core';
const router: Routes = [
  {
    path: 'products',
    component: ProductsComponent,
    children: [
      {
        path: 'arthrid',
        component: ArthridComponent
      },
      {
        path: 'mpg',
        component: MemoplusgoldComponent
      },
      {
        path: 'cdb',
        component: CogentdbComponent
      },
      {
        path: 'tips',
        loadChildren: () => TipsModule
      },
    ]
  },
];

// export const routes: ModuleWithProviders = RouterModule.forRoot(router);
export const appRoutes = RouterModule.forChild(router);
