import { FooterService } from './../../../services/footer.service';
import { ThemingService } from './../../../services/theming.service';
import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-radialmenu',
  templateUrl: './radialmenu.component.html',
  styleUrls: ['./radialmenu.component.css']
})
export class RadialmenuComponent implements OnInit {

  @ViewChild('imgRef', {read: ElementRef}) imgRef: ElementRef;
  constructor(
    private fos: FooterService,
    private ths: ThemingService,
    private renderer: Renderer2) {

      this.fos.footerValue.subscribe(data => {
        this.ths.GetThemeValue.subscribe(theme => {
          let imgURL: string;
          if (theme) {
            imgURL = theme.navbarLogoURL;
          } else {
            imgURL = '#';
          }
          this.renderer.setStyle(this.imgRef.nativeElement, 'src', imgURL);
        });
      });
    }

  ngOnInit() {

  }

}
