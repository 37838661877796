import { OnInit, Injectable } from '@angular/core';
import { FirebaseModule, FirebaseModules } from './../services/firebase.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from 'angularfire2/firestore';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class FirebaseServiceInterface<T>  implements OnInit {
  private serviceModel: T;
  serviceCollection: AngularFirestoreCollection<T>;
  private $serviceList: BehaviorSubject<T[]>;
  private servicelist: T[] = [];
  module: FirebaseModule;
  private subject: BehaviorSubject<any>;
  observable: Observable<T[]>;

  constructor(
    private afs: AngularFirestore,
    private fsModule: FirebaseModules,
    private moduleToUse
  ) {
    const moduleInUse = this.moduleToUse;
    this.$serviceList = new BehaviorSubject(this.servicelist);
    this.module = this.fsModule.FirebaseModule.filter(
      d => d.moduleName.includes(moduleInUse)
    )[0];
    this.ngOnInit();
    // console.log(moduleInUse + ' Service: Initialized');
  }

  ngOnInit() {
    this.serviceCollection = this.afs
            .collection<T>(this.module.firebaseModuleDirectory);
    this.serviceCollection
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
          let data: any;
          data = a.payload.doc.data() as T;
          data.docID = a.payload.doc.id;
          return data;
        });
      })
    ).subscribe(data => {
      this.servicelist = data;
      this.$serviceList
            .next(Object.assign({},
              this.servicelist));
      this.definedFunctionFromSubscription(this.servicelist);
    });

    this.observable = this.serviceCollection.valueChanges();

    this.OnInitExtension();
  }

  OnInitExtension() {
  }
  get serviceList() {
    return this.$serviceList.asObservable();
  }

  find(obj: T, col: string, operator: any) {
    this.subject = new BehaviorSubject(obj);
    this.observable = this.subject.pipe(
      switchMap(empid => this.afs
        .collection<T>(this.module.firebaseModuleDirectory,
        ref => ref
          .where(col, operator, empid[col]))
        .valueChanges(),
      ),
    );

    if (this.observable !== undefined) {
      return { emp: this.observable, res: 'success' };
    } else {
      return { emp: undefined, res: 'failed' };
    }
  }

  create(obj: T) {
    this.serviceCollection
      .add(JSON.parse(JSON.stringify(obj)));
  }

  update(obj: T) {
    // console.log(this.module.firebaseModuleDirectory + ' docID: ' + obj['docID']);
    this.afs.doc(`${this.module.firebaseModuleDirectory}${obj['docID']}`)
    .update(JSON.parse(JSON.stringify(obj)));
  }

  include( docName, docColumn, docId) {
    const serviceCollection: AngularFirestoreCollection = this.afs
            .collection<T>(docName);
    const serviceList: BehaviorSubject<any[]> =  new BehaviorSubject([{}]);

    serviceCollection
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
          let data: any;
          data = a.payload.doc.data() as T;
          data.docID = a.payload.doc.id;
          return data;
        });
      })
    ).subscribe(data => {
      serviceList.next(data.filter( data => data[docColumn] === docId));
    });

    return serviceList.asObservable();
  }

  definedFunctionFromSubscription(result) {
  }
}
