import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bits',
  templateUrl: './bits.component.html',
  styleUrls: ['./bits.component.css']
})
export class BitsComponent implements OnInit {


  brainBoosters: any[] = [
    {
      title:'Omega 3 Fatty Acids',
      subTitle: 'Salmon, Flax Seeds, Walnuts',
      imgSRC: '/assets/products/memoplusgold/bit1.png'
    },
    {
      title:'Flavonoids',
      subTitle: 'Green Tea, Dark Chocolate',
      imgSRC: '/assets/products/memoplusgold/bit1.png'
    }
    ,{
      title:'Vitamin D',
      subTitle: 'Fish Liver, Mushrooms, Soy Milk',
      imgSRC: '/assets/products/memoplusgold/bit1.png'
    }
    ,{
      title:'Vitamin E',
      subTitle: 'Green Tea, Dark Chocolate',
      imgSRC: '/assets/products/memoplusgold/bit1.png'
    }
    ,{
      title:'Calcium, Zinc, Selenium, Copper, Iron',
      subTitle: 'Milk, Almond, Eggs, Oysters, Lentils',
      imgSRC: '/assets/products/memoplusgold/bit1.png'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
