import { ThemingService } from './../../services/theming.service';
import { FooterService } from './../../services/footer.service';
import { Footer } from './../../models/footer.model';
import { Theme } from './../../models/theme.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent implements OnInit {

  footer: Footer = <Footer>{
    facebookURL: 'memoplusgold.facebook',
    instagramURL: 'memoplusgold.ig',
    twitterURL: 'memoplusgold.twitter',
    lazadaURL: 'memoplusgold.lazada',
    shopeeURL: 'memoplusgold.shopee',
    youtubeURL: 'memoplusgold.youtube',
    product: 'mpg'
  };

  theme: Theme = <Theme> {
    navbarColor: '',
    navbarFontColor: 'white',
    footerColor: '#8cc63f',
    footerFontColor: 'white',
    sidePanelColor: '',
    sidePanelFontColor: '',
    navbarLogoURL: '/assets/products/memoplusgold/mpg-logo.png'
  };

  constructor(
    private fos: FooterService,
    private ths: ThemingService) {
    this.fos.footerData = this.footer;
    this.ths.SetThemeValue = this.theme;

    // console.log(this.theme);
  }

  ngOnInit() {
  }

}
