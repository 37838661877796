import { TipsModule } from './../tips/tips.module';
import { MpgClinicalstudiesComponent } from './memoplusgold/mpg-clinicalstudies/mpg-clinicalstudies.component';
import { ArthridIngredientsComponent } from './arthrid/arthrid-ingredients/arthrid-ingredients.component';
import { CogentDiabetes2Component } from './cogentdb/cogent-diabetes2/cogent-diabetes2.component';
import { CogentDiabetesComponent } from './cogentdb/cogent-diabetes/cogent-diabetes.component';
import { CogentClinicalStudiesComponent } from './cogentdb/cogent-clinical-studies/cogent-clinical-studies.component';
import { CogentAboutComponent } from './cogentdb/cogent-about/cogent-about.component';
import { CogentdbComponent } from './cogentdb/cogentdb.component';
import { ArthridComponent } from './arthrid/arthrid.component';
import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { SharedModule } from '../shared/shared.module';
import { appRoutes } from './product.routing';
import { MemoplusgoldComponent } from './memoplusgold/memoplusgold.component';
import { MobileFaqComponent } from './memoplusgold/mobile-faq/mobile-faq.component';
import { MpgIngredientsComponent } from './memoplusgold/mpg-ingredients/mpg-ingredients.component';

@NgModule({
  imports: [
    CommonModule,
    appRoutes,
    SharedModule,
    FormsModule,
    TipsModule.forRoot()
  ],
  declarations: [
    ProductsComponent,
    ArthridComponent,
    CogentdbComponent,
    MemoplusgoldComponent,
    MobileFaqComponent,
    MpgIngredientsComponent,
    CogentAboutComponent,
    CogentClinicalStudiesComponent,
    CogentDiabetesComponent,
    CogentDiabetes2Component,
    ArthridIngredientsComponent,
    MpgClinicalstudiesComponent
  ],
  entryComponents: [
    MpgIngredientsComponent,
    CogentAboutComponent,
    CogentDiabetesComponent,
    CogentDiabetes2Component,
    CogentClinicalStudiesComponent,
    ArthridIngredientsComponent,
    MobileFaqComponent,
    MpgClinicalstudiesComponent
  ]
})
export class ProductsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ProductsModule
    };
  }
}
