import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogentdb',
  templateUrl: './cogentdb.component.html',
  styleUrls: ['./cogentdb.component.css']
})
export class CogentdbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
