import { RepeatElements } from './../../../models/repeat.model';
import { AccordionComponent } from './../../shared/accordion/accordion.component';
import { SwitcherComponent } from './../../shared/switcher/switcher.component';
import { FooterService } from './../../../services/footer.service';
import { Footer } from './../../../models/footer.model';
import { Card } from './../../../models/card.model';
import { CardComponent } from './../../shared/card/card.component';
import { DynamicComponent } from './../../shared/dynamic/dynamic.component';
import {
	RenderContainerElement,
	ElementAttributes
} from './../../../models/render.model';
import { Component, OnInit } from '@angular/core';
import { ArthridIngredientsComponent } from './arthrid-ingredients/arthrid-ingredients.component';
import { ThemingService } from '../../../services/theming.service';
import { Theme } from '../../../models/theme.model';

@Component({
	selector: 'app-arthrid',
	templateUrl: './arthrid.component.html',
	styleUrls: [
		'./arthrid.component.css'
	]
})
export class ArthridComponent implements OnInit {
  shop;
	footer: Footer = <Footer>{
		facebookURL: 'arthrid.facebook',
		instagramURL: 'arthrid.ig',
		twitterURL: 'arthrid.twitter',
		lazadaURL: 'arthrid.lazada',
		shopeeURL: 'arthrid.shopee',
		youtubeURL: 'arthrid.youtube',
		product: 'arthrid'
	};

	theme: Theme = <Theme>{
		navbarColor: '',
		navbarFontColor: 'white',
		footerColor: '#208dbb',
		footerFontColor: 'white',
		sidePanelColor: '',
		sidePanelFontColor: ''
	};

	aboutIllnesses: RepeatElements = new RepeatElements({
		parentTag: 'ul',
		tag: 'li',
		isGrid: false,
		elements: [
			'Rheumatoid Arthritis',
			'Osteoarthritis',
			'Juvenile Rheumatoid Arthritis',
			'Spondylitis',
			'Acute Muscular Rheumatism',
			'Lower back pain'
		],
		parentClass: [],
		childrenClass: [
			'content-text'
		]
	});

	aboutSection = <RenderContainerElement>{
		elementComponentType: DynamicComponent,
		elementName: 'about',
		elementTag: 'div',
		isGrid: true,
		elementClass: [
			'container',
			'py-5',
			'mt-5'
		],
		childElements: [
			<RenderContainerElement>{
				elementComponentType: DynamicComponent,
				elementName: 'about',
				elementTag: 'div',
				elementClass: [
					'uk-grid',
					'py-5'
				],
				isGrid: true,
				childElements: [
					<RenderContainerElement>{
						elementTag: 'div',
						elementClass: [
							'pt-3',
							'uk-width-1-2@m'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'img',
								elementClass: [
									'w-100'
								],
								elementAttribute: {
									src: '/assets/products/arthrid/runner.png'
								}
							}
						]
					},
					<RenderContainerElement>{
						elementTag: 'div',
						elementClass: [
							'uk-width-1-3@m',
							'uk-text-justify',
							'pt-5',
							'ml-0'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'h2',
								elementClass: [
									'_blue-header'
								],
								elementAttribute: {
									text: 'The Freedom to Move with Arthrid'
								}
							},
							<RenderContainerElement>{
								elementTag: 'p',
								elementClass: [
									'content-text'
								],
								elementAttribute: {
									text:
										// tslint:disable-next-line:max-line-length
										'Say goodbye to pain with Arthrid! Arthrid is an herbal dietary supplement that aids in alleviating joint pain.'
								}
							},
							<RenderContainerElement>{
								elementTag: 'p',
								elementClass: [
									'content-text'
								],
								elementAttribute: {
									text:
										// tslint:disable-next-line:max-line-length
										'It has been proven effective in the management of the following joint-related illnesses:'
								}
							},
							this.aboutIllnesses._parent,
							<RenderContainerElement>{
								elementTag: 'p',
								elementClass: [
									'content-text'
								],
								elementAttribute: {
									text:
										// tslint:disable-next-line:max-line-length
										'For best results, take two (2) capsules of Arthrid two times a day, after a meal. After two months, the ideal maintenance is one (1) capsule two times a day after eating.'
								}
							},
							<RenderContainerElement>{
								elementTag: 'h2',
								elementClass: [
									'content-text'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'i',
										elementClass: [
											'fas',
											'fa-arrow-circle-right',
											'pr-1'
										],
										childElements: []
									},
									<RenderContainerElement>{
										elementTag: 'a',
										elementClass: [
											'content-text'
										],
										elementAttribute: {
											href: '/#/products/tips/arthrid',
											text: 'See Health Bits Here!'
										}
									}
								]
							}
						]
					}
				]
			}
		]
	};

	ingredientsSectionDesktop = new RenderContainerElement({
		elementComponentType: ArthridIngredientsComponent,
		elementName: 'ing-comp',
		elementTag: 'div',
		isGrid: false
	});

	ingredientsSectionMobile = new RenderContainerElement({
		elementComponentType: DynamicComponent,
		elementName: 'ing-comp',
		elementTag: 'div',
		isGrid: false,
		elementClass: [
      'uk-hidden@m',
      'arthrid-ing'
		],
		childElements: [
			<RenderContainerElement>{
				elementTag: 'div',
				isGrid: false,
				elementClass: [
					'px-3',
					'w-75',
					'm-auto',
					'py-5'
				],
				childElements: [
					<RenderContainerElement>{
						elementTag: 'h3',
						elementClass: [
							'uk-text-center',
							'py-3'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-card',
									// 'uk-card-primary',
									// 'uk-border-rounded',
									'uk-width-small@m',
									'uk-text-center',
									'py-2'
								],
								elementAttribute: <ElementAttributes>{
									text: 'Arthrids Ayurvedic Herbs'
								}
							}
						]
					},
					new RenderContainerElement({
						elementName: 'boswella',
						elementTag: 'div',
						isGrid: true,
						elementClass: [
							'uk-grid'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-width-expand'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/boswellia.png'
										}
									}
								]
							},
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-width-1-4',
									'm-auto'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/boswella-1.png'
										}
									},
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'assets/products/arthrid/boswella-2.png'
										}
									},
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/boswella-3.png'
										}
									}
								]
							}
						]
					}),
					new RenderContainerElement({
						elementName: 'boswella',
						elementTag: 'div',
						isGrid: true,
						elementClass: [
							'uk-grid'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-width-1-4',
									'm-auto'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/piper-1.png'
										}
									},
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/piper-2.png'
										}
									},
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/piper-3.png'
										}
									}
								]
							},
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-width-expand'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'img',
										elementClass: [
											'w-100'
										],
										elementAttribute: {
											src:
												'/assets/products/arthrid/piper.png'
										}
									}
								]
							}
						]
					})
				]
			}
		]
	});

	otherTypesOfArthritis: RepeatElements = new RepeatElements({
		parentTag: 'ul',
		tag: 'li',
		isGrid: false,
		elements: [
			'Juvenile Arthritis',
			'Gout',
			'Pseudogout',
			'Traumatic Arthritis',
			'Septic Arthritis',
			'Psoriatic Arthritis',
			'Arthritis associated with systemic diseases such as Rheumatoid Fever, Fibromyalgia, Scleroderma and Lupus'
		],
		parentClass: [],
		childrenClass: [
			'content-text'
		]
	});

	arthritis = <RenderContainerElement>{
		elementComponentType: DynamicComponent,
		elementName: 'faq',
		elementTag: 'div',
		isGrid: false,
		elementClass: [
			'w-100',
			'powderblue',
			'py-md-5'
		],
		childElements: [
			<RenderContainerElement>{
				elementComponentType: DynamicComponent,
				elementName: 'aaa',
				elementTag: 'div',
				isGrid: false,
				elementClass: [
					'container',
					'py-5'
				],
				childElements: [
					<RenderContainerElement>{
						elementTag: 'h3',
						elementClass: [
							'uk-text-center',
							'uk-hidden@m'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-card',
									'uk-card-primary',
									'uk-border-rounded',
									'uk-width-small@m',
									'uk-text-center',
									'py-2'
								],
								elementAttribute: <ElementAttributes>{
									text: 'Arthritis'
								}
							}
						]
					},
					<RenderContainerElement>{
						elementTag: 'div',
						elementClass: [
							'uk-grid',
							'p-3',
							'pt-md-5',
							'pb-md-2',
							'mx-0',
							'uk-card',
							'uk-card-default'
							// 'round-border',
							// 'teal'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementName: 'bluebodyContainer',
								elementClass: [
									'uk-width-1-3',
									'm-auto',
									'pl-0'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'img',
										elementName: 'bluebodyImg',
										elementClass: [],
										elementAttribute: {
											src:
												'/assets/products/arthrid/bluebody.gif'
										}
									}
								]
							},
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'uk-width-expand@m',
									'uk-width-1-1',
									'pl-0',
									'pl-md-3',
									'mr-md-4'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'div',
										elementClass: [
											'my-3'
										],
										childElements: [
											<RenderContainerElement>{
												elementTag: 'h4',
												elementClass: [
													'uk-card',
													'uk-card-primary',
													'uk-border-rounded',
													'uk-width-medium',
													'uk-text-center',
													'py-2'
												],
												elementAttribute: <ElementAttributes>{
													text: 'What is Arthritis'
												}
											},
											<RenderContainerElement>{
												elementTag: 'p',
												elementClass: [
													'uk-text-justify'
												],
												elementAttribute: <ElementAttributes>{
													text:
														'Arthritis literally means the inflammation of a joint caused by the destruction of the articular cartilage on the ends of the bones as they meet in the joint. It is a progressive disease which, left untreated, could lead to permanent disability.'
												}
											}
										]
									},
									<RenderContainerElement>{
										elementTag: 'div',
										elementClass: [
											'my-3'
										],
										childElements: [
											<RenderContainerElement>{
												elementTag: 'h4',
												elementClass: [
													'uk-card',
													'uk-card-primary',
													'uk-border-rounded',
													'uk-width-medium',
													'uk-text-center',
													'py-2'
												],
												elementAttribute: <ElementAttributes>{
													text: 'Types of Arthritis'
												}
											},
											<RenderContainerElement>{
												elementTag: 'div',
												elementClass: [
													'uk-grid',
													'uk-child-width-1-1',
													'uk-child-width-1-2@m'
												],
												childElements: [
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'my-3'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'h4',
																elementClass: [],
																elementAttribute: <ElementAttributes>{
																	text:
																		'OSTEOARTHRITIS'
																}
															},
															<RenderContainerElement>{
																elementTag: 'p',
																elementClass: [
																	'uk-text-justify'
																],
																elementAttribute: <ElementAttributes>{
																	text:
																		'Most common chronic condition of the joints that occurs when the cartilage or cushion between joints break down, causing pain, stiffness and swelling.'
																}
															}
														]
													},
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'my-3'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'h4',
																elementClass: [],
																elementAttribute: <ElementAttributes>{
																	text:
																		'RHEUMATOID ARTHRITIS'
																}
															},
															<RenderContainerElement>{
																elementTag: 'p',
																elementClass: [
																	'uk-text-justify'
																],
																elementAttribute: <ElementAttributes>{
																	text:
																		'An autoimmune disease, where the body’s immune system mistakenly attacks the joints causing inflammation and damage to the cartilage and the bones.'
																}
															}
														]
													},
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'my-3',
															'uk-width-expand@m'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'div',
																elementClass: [
																	'my-3',
																	'uk-width-1-2'
																],
																childElements: [
																	<RenderContainerElement>{
																		elementTag:
																			'h4',
																		elementClass: [
																			'uk-card',
																			'uk-card-primary',
																			'uk-border-rounded',
																			'uk-text-center',
																			'py-2'
																		],
																		elementAttribute: <ElementAttributes>{
																			text:
																				'Other Types'
																		}
																	},
																	this
																		.otherTypesOfArthritis
																		._parent
																]
															}
														]
													}
												]
											}
										]
									}
								]
							}
						]
					},
					<RenderContainerElement>{
						elementTag: 'div',
						elementClass: [
							'mx-0'
						],
						childElements: [
							<RenderContainerElement>{
								elementTag: 'div',
								elementClass: [
									'my-3',
									'px-3',
									'py-5',
									'uk-card',
									'uk-card-default'
									// 'mr-md-3'
									// 'uk-width-1-3@m',
									// 'round-border',
									// 'green'
								],
								childElements: [
									<RenderContainerElement>{
										elementTag: 'div',
										elementClass: [
											'uk-grid',
											'mx-0',
											'uk-child-width-1-2@m'
										],
										childElements: [
											<RenderContainerElement>{
												elementTag: 'div',
												elementClass: [
													'pl-0',
													'px-lg-5'
												],
												childElements: [
													<RenderContainerElement>{
														elementTag: 'h4',
														elementClass: [
															'uk-card',
															'uk-card-primary',
															'uk-border-rounded',
															'uk-text-center',
															'py-2'
														],
														elementAttribute: <ElementAttributes>{
															text: 'Most Common Symptom'
														}
													},
													// <RenderContainerElement>{
													//   elementTag: 'h4',
													//   elementClass: [],
													//   elementAttribute: <ElementAttributes>{
													//     text: 'Most Common Symptom: '
													//   }
													// },
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'w-50',
															'm-auto'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'img',
																elementClass: [
																	'w-100'
																],
																elementAttribute: {
																	src:
																		'/assets/products/arthrid/joint-pain.png'
																}
															}
														]
													},
													<RenderContainerElement>{
														elementTag: 'p',
														elementClass: [
															'content-text',
															'uk-text-center'
														],
														elementAttribute: <ElementAttributes>{
															text: 'Joint pain'
														}
													}
												]
											},
											<RenderContainerElement>{
												elementTag: 'div',
												elementClass: [
													'pl-0',
													'px-lg-5'
												],
												childElements: [
													<RenderContainerElement>{
														elementTag: 'h4',
														elementClass: [
															'uk-card',
															'uk-card-primary',
															'uk-border-rounded',
															'uk-text-center',
															'py-2'
														],
														elementAttribute: <ElementAttributes>{
															text:
																'Other Symptoms'
														}
													},
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'w-50',
															'm-auto'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'img',
																elementClass: [
																	'w-100'
																],
																elementAttribute: {
																	src:
																		'/assets/products/arthrid/joint-pain2.png'
																}
															}
														]
													},
													<RenderContainerElement>{
														elementTag: 'p',
														elementClass: [
															'content-text',
															'uk-text-center'
														],
														elementAttribute: <ElementAttributes>{
															text:
																'Swelling, tenderness, redness, and stiffness in the joint area'
														}
													}
												]
											},

											<RenderContainerElement>{
												elementTag: 'div',
												elementClass: [
													'my-3',
													'px-3',
													'py-4',
													'uk-width-1-1@m',
													'm-lg-auto'
													// 'round-border'
												],
												childElements: [
													<RenderContainerElement>{
														elementTag: 'div',
														elementClass: [
															'my-3',
															'px-lg-5'
														],
														childElements: [
															<RenderContainerElement>{
																elementTag:
																	'h4',
																elementClass: [
																	'uk-card',
																	'uk-card-primary',
																	'uk-border-rounded',
																	'uk-text-center',
																	'py-2'
																],
																elementAttribute: <ElementAttributes>{
																	text:
																		'Arthrid’s Herbal Ingredients'
																}
															},
															<RenderContainerElement>{
																elementTag:
																	'div',
																elementClass: [
																	'px-4',
																	'uk-width-1-1@m',
																	'uk-grid',
																	'uk-child-width-1-2@m',
																	'm-0'
																],
																childElements: [
																	<RenderContainerElement>{
																		elementTag:
																			'div',
																		elementClass: [
																			'uk-text-justify',
																			'pl-0'
																		],
																		childElements: [
																			<RenderContainerElement>{
																				elementTag:
																					'p',
																				elementClass: [
																					'content-text',
																					'uk-text-justify'
																				],
																				elementAttribute: <ElementAttributes>{
																					text:
																						// tslint:disable-next-line:max-line-length
																						'While modern medical science has yet to find a cure for Arthritis, herbs such as Boswellia serrata and Piper longum have been verified effective in relieving arthritis. These herbs have been tested and proven to possess anti-inflammatory, anti- arthritic and analgesic properties.'
																				}
																			}
																		]
																	},
																	// Green pot Image
																	<RenderContainerElement>{
																		elementTag:
																			'div',
																		elementName:
																			'greenpotContainer',
																		elementClass: [
																			'uk-width-1-4@m',
																			'uk-width-1-2',
																			'm-auto',
																			'pl-0'
																		],
																		childElements: [
																			<RenderContainerElement>{
																				elementTag:
																					'img',
																				elementName:
																					'greenpotImg',
																				elementClass: [],
																				elementAttribute: {
																					src:
																						'/assets/products/arthrid/green-pot.png'
																				}
																			}
																		]
																	}
																]
															}
														]
													}
												]
											}
										]
									}
								]
							}
						]
					}
				]
			}
		]
	};

	clinicalStudiesMobile = <RenderContainerElement>{
		elementName: 'clinicalStudies',
		elementComponentType: DynamicComponent,
		elementTag: 'div',
		elementClass: [
			'container',
			'uk-hidden@m'
		],
		isGrid: false,
		childElements: [
			new RenderContainerElement({
				elementName: 'clinicalStudies',
				elementComponentType: DynamicComponent,
				elementTag: 'div',
				elementClass: [
					'my-5'
				],
				isGrid: false,
				childElements: [
					<RenderContainerElement>{
						elementTag: 'h2',
						isGrid: false,
						elementClass: [
							'uk-text-center'
						],
						elementAttribute: {
							text: 'Clinical Studies'
						}
					},
					new RenderContainerElement({
						elementName: 'cardSection',
						elementComponentType: CardComponent,
						elementTag: 'div',
						isGrid: true,
						childElements: [
							<Card>{
								title:
									'Kimmatkar et al. Phytomedicine 10:3-7 (2003)',
								body:
									// tslint:disable-next-line:max-line-length
									'In a recent placebo-controlled study, Boswellia serrata was significantly effective in relieving symptoms compared to placebo.'
							},
							<Card>{
								title:
									// tslint:disable-next-line:max-line-length
									'Efficacy and Tolerability of Boswellia Serrata Extract in the Treatment of Osteoarthritis of the Knee - MS Orthopedics, Indira Gandhi Medical College, Nagpur, India',
								body:
									// tslint:disable-next-line:max-line-length
									'In a randomized double blind placebo-controlled trial, patients receiving extracts of Boswellia serrata reported decrease in knee pain, increased knee flexion and walking distance, improvement in the capacity to climb stairs, better ability in kneeling, cross-legged sitting, and squatting. The range of knee movement was also improved, while there was a decrease in the frequency of swelling in the knee joint.'
							},
							<Card>{
								// tslint:disable-next-line:max-line-length
								title:
									'Vedhanayaki G., Shastri GV, Kuruvilla A: Analgesic Activity of Piper Longum Linn. Root, Dept. of Pharmacology, PSG Institute of Medical Sciences & Research, India',
								body:
									'Piper longum showed potent analgesic activity on laboratory mice fed with Piper longum root powder.'
							},
							<Card>{
								title:
									'Kulkarni et al; Double-blind placebo-controlled cross-over study, Pune, India',
								// tslint:disable-next-line:max-line-length
								body:
									'In the study "Efficacy of an Ayurvedic Formulation in Rheumatoid Arthritis," patients treated with an indigenous Ayurvedic formulation experienced general pain relief. A significant reduction in the time duration of morning stiffness and joint score was also observed.'
							}
						]
          }),
          <RenderContainerElement> {
            elementTag: 'div',
						isGrid: false,
						elementClass: [
              'broken-lines',
              'mr-lg-4',
              'py-4'
            ],
            childElements: [
              <RenderContainerElement> {
                elementTag: 'h4',
                isGrid: false,
                elementClass: [
                  'mb-0',
                ],
                childElements: [
                  <RenderContainerElement> {
                    elementTag: 'a',
										elementClass: [
											'content-text'
										],
										elementAttribute: {
											href: '/assets/products/arthrid/clinicalstudy.pdf',
											text: 'Click here to read the complete details.'
										}
                  }
                ]
              }
            ]
          },
          <RenderContainerElement> {
            elementTag: 'div',
						isGrid: false,
						elementClass: [
              'broken-lines',
              'mr-lg-4',
              'py-4',
              'mt-3'
            ],
            childElements: [
              <RenderContainerElement> {
                elementTag: 'h4',
                isGrid: false,
                elementClass: [
                  'mb-0',
                ],
                childElements: [
                  <RenderContainerElement> {
                    elementTag: 'a',
										elementClass: [
											'content-text'
										],
										elementAttribute: {
											href: '/assets/products/arthrid/dost/dost.pdf',
											text: 'DOST Lab Results'
										}
                  }
                ]
              }
            ]
          }
				]
			})
		]
	};

	clinicalStudiesDesktop = new RenderContainerElement({
		elementComponentType: AccordionComponent,
		elementName: 'clinicalStudies',
		elementTag: 'div',
		isGrid: false,
		elementClass: [
			'uk-visible@m'
		],
		childElements: [
			{
				placeholdertype: 'switcher',
				placeholderDOM: 'div',
				placeholderInput: 'tips',
				placeholderContent: [
					{
						title: 'Kimmatkar et al. Phytomedicine 10:3-7 (2003)',
						bodyContent:
							// tslint:disable-next-line:max-line-length
							'In a recent placebo-controlled study, Boswellia serrata was significantly effective in relieving symptoms compared to placebo.'
					},
					{
						title:
							// tslint:disable-next-line:max-line-length
							'Efficacy and Tolerability of Boswellia Serrata Extract in the Treatment of Osteoarthritis of the Knee - MS Orthopedics, Indira Gandhi Medical College, Nagpur, India',
						bodyContent:
							// tslint:disable-next-line:max-line-length
							'In a randomized double blind placebo-controlled trial, patients receiving extracts of Boswellia serrata reported decrease in knee pain, increased knee flexion and walking distance, improvement in the capacity to climb stairs, better ability in kneeling, cross-legged sitting, and squatting. The range of knee movement was also improved, while there was a decrease in the frequency of swelling in the knee joint.'
					},
					{
						title:
							// tslint:disable-next-line:max-line-length
							'Vedhanayaki G., Shastri GV, Kuruvilla A: Analgesic Activity of Piper Longum Linn. Root, Dept. of Pharmacology, PSG Institute of Medical Sciences & Research, India',
						bodyContent:
							'Piper longum showed potent analgesic activity on laboratory mice fed with Piper longum root powder.'
					},
					{
						title:
							'Kulkarni et al; Double-blind placebo-controlled cross-over study, Pune, India',
						bodyContent:
							// tslint:disable-next-line:max-line-length
							'In the study "Efficacy of an Ayurvedic Formulation in Rheumatoid Arthritis", patients treated with an indigenous Ayurvedic formulation experienced general pain relief. A significant reduction in the time duration of morning stiffness and joint score was also observed.'
					}
				]
			}
		]
	});

	displayElements: any[] = [
		this.aboutSection,
		this.ingredientsSectionDesktop,
		this.ingredientsSectionMobile,
		this.clinicalStudiesDesktop,
		this.clinicalStudiesMobile,
		this.arthritis
	];

	constructor (private fos: FooterService, private ths: ThemingService) {
		this.fos.footerData = this.footer;
		this.ths.SetThemeValue = this.theme;
	}

	ngOnInit () {
    this.shop  = {
        mpg: {
        imgURL: './assets/products/shop/shop_cdb.png',
        shopeeURL: 'https://shopee.ph/winlodale',
        lazadaURL: 'https://www.lazada.com.ph/shop/memo-plus-gold-official-store'
      },
      arthrid: {
        imgURL: './assets/products/shop/shop_arthrid.png',
        shopeeURL: 'https://shopee.ph/arthridofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/arthrid-official-store'
      },
      cdb: {
        imgURL: './assets/products/shop/shop_mpg.png',
        shopeeURL: 'https://shopee.ph/cogentofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/cogent-db-official-store'
      }
    };
  }
}
