import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shopbtn',
  templateUrl: './shopbtn.component.html',
  styleUrls: ['./shopbtn.component.css']
})
export class ShopbtnComponent implements OnInit {

  @Input() properties: any;
  shopProperties;
  dropDownLinks: boolean;
  constructor() { }

  ngOnInit() {
    this.shopProperties = this.properties;
  }

  showDropDownLinks() {
    this.dropDownLinks = this.dropDownLinks ? false : true;
  }

}
