import { Injectable, OnInit } from '@angular/core';

export class FirebaseModule {
  public moduleName: string;
  public firebaseModuleDirectory: string;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseModules {
  private availableModules: FirebaseModule[] = [];
  constructor() {
    this.availableModules = [
      {
        moduleName: 'VisitorCount',
        firebaseModuleDirectory: '/visitorCount/'
      },
    ];
  }

  get FirebaseModule() {
    return this.availableModules;
  }
}
