import { CounterModel } from './../models/counter.model';
import { BehaviorSubject } from 'rxjs';
import { FirebaseServiceInterface } from './../models/service.model';
import { Injectable } from '../../../node_modules/@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from 'angularfire2/firestore';
import { FirebaseModules } from './firebase.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CounterFirebaseService extends FirebaseServiceInterface<CounterModel> {

  constructor(
    private firestoreservice: AngularFirestore,
    private firestoremodule: FirebaseModules) {
    super(firestoreservice, firestoremodule, 'VisitorCount');
  }


  updateVisitorCount(docID, document) {
    const counterDoc = this.firestoreservice.doc(`${this.module.firebaseModuleDirectory}${docID}`);
    counterDoc.set(JSON.parse(JSON.stringify({count: document})));
  }
}
