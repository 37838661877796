import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogent-diabeatit',
  templateUrl: './cogent-diabeatit.component.html',
  styleUrls: ['./cogent-diabeatit.component.css']
})
export class CogentDiabeatitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
