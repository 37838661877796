import { TipsComponent } from './tips.component';
import { ArthridComponent } from './arthrid/arthrid.component';
import { CogentdbComponent } from './cogentdb/cogentdb.component';
import { MemoplusgoldComponent } from './memoplusgold/memoplusgold.component';
import { Routes, RouterModule } from '@angular/router';
const router: Routes = [
  {
    path: 'tips',
    component: TipsComponent,
    children: [
      {
        path: 'arthrid',
        component: ArthridComponent
      },
      {
        path: 'mpg',
        component: MemoplusgoldComponent
      },
      {
        path: 'cdb',
        component: CogentdbComponent
      }
    ]
  },
];
export const appRoutes = RouterModule.forChild(router);
