import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-faq',
  templateUrl: './mobile-faq.component.html',
  styleUrls: ['./mobile-faq.component.css']
})
export class MobileFaqComponent implements OnInit {
  public questions: Question[];

  typing: boolean;
  selectedQuestion: Question;

  constructor() {
  }

  ngOnInit() {
    this.typing = true;
    this.questions = [
      {
        id: 0,
        question: '-- Select A Question --',
        answer: 'Please Select a Question'
      },
      {
        id: 1,
        question: 'HOW DOES MEMO PLUS GOLD WORK?',
        answer: 'MEMO PLUS GOLD enhances the brain’s cholinergic neurotransmitter system. This helps improve the efficiency of nerve impulse transmission by strengthening the neuron’s synaptic activity. Bacopa monniera boosts the protein kinase activity while increasing the protein and serotonin levels in the hippocampus - the brain area associated with memory formation.'
      },
      {
        id: 2,
        question: 'HOW DO I KNOW THE PRODUCT IS WORKING AND HOW LONG DOES IT TAKE TO NOTICE A DIFFERENCE?',
        answer: 'MEMO PLUS GOLD should be used for at least a period of 12 weeks at 2 capsules a day to have a significant effect. After 12 weeks, one can expect enhanced memory, improved learning pace, reduced anxiety & stress, improved general well-being and many more. Continuous daily usage is recommended after 12 weeks to guarantee its health benefit.'
      },
      {
        id: 3,
        question: 'WHAT ARE THE FORMULATIONS?',
        answer: 'Brahmi+T contains herbs which can aid to calm nerves, improve stress adaptation, help reduce effects of mild anxiety and nervous tension. These active herbal components provide endurance and stamina. Brahmi+T can assist help by providing the correct blend of nutrients and herbs that will protect you against harmful free radical damage. It can also protect and maintain the health of capillaries, thereby assisting peripheral circulation.'
      },
      {
        id: 4,
        question: 'WHAT ARE THE OTHER HEALTH BENEFITS OF BRAHMI?',
        answer: 'The potential healing benefits of Brahmi extend beyond the brain. Traditional Ayurvedic practitioners utilized the herb for a number of conditions and usages: (1) blood cleanser, (2) treatment for fever, inflammations, joint pain and skin problems such as eczema, psoriasis and ulcers, (3) stimulate hair, skin and nail growth and (4) treatment for irritable bowel syndrome (IBS).'
      },
      {
        id: 5,
        question: 'WHAT IS THE RECOMMENDED DOSAGE AND USAGE OF MEMO PLUS GOLD?',
        answer: 'For best results, we recommend taking one (1) capsule twice a day after meals.  For students taking their finals or board exams: take the recommended dosage for the duration of the entire school year or during the review period. For children 7 to 14 years old: one (1) capsule after breakfast. For children who have difficulty taking capsules: open the capsule and mix the content to a teaspoon of honey or multivitamin syrup, also into a glass of milk or a juice drink.'
      },
      {
        id: 6,
        question: 'ARE HERBAL REMEDIES SAFE FOR CHILDREN?',
        answer: 'Yes, children can safely use herbal products that have no side effects. MEMO PLUS GOLD is proven safe and non-toxic by the Central Drug Research Institute and credibly backed by successful clinical trials in India and Australia.'
      },
      {
        id: 7,
        question: 'IS IT SAFE TO TAKE HERBAL REMEDIES ALONG WITH OTHER PRESCRIPTION?',
        answer: 'Yes, Brahmi possesses no known side effects or toxicity at normal doses. MEMO PLUS GOLD is safe to take together with any medicine, vitamins and/or other supplements.'
      },
      {
        id: 8,
        question: 'HOW DOES MEMO PLUS GOLD ADDRESS AGING ISSUES?',
        answer: 'MEMO PLUS GOLD as an antioxidant slows brain aging, based on a research findings reported in an Indian Journal of Expt. Biol, June 34, 523-526 1996. Brahmi has also been traditionally used as an anti-anxiety, anti-fatigue and memory-strengthening herb. We need good blood flow to and from our brain to remove wastes and bring nutrients.'
      },
      {
        id: 9,
        question: 'HOW DO BRAIN NEURONS WORK IN RELATION TO MEMORY?',
        answer: 'Our brain has over 100 billion neurons, which allow us to acquire, process and respond to information. But certain factors such as eating junk food, staying up late and being exposed to pollution cause damage to the brain neurons and result to one’s forgetfulness.  Nourishing your brain neurons with an all-natural supplement, like MEMO PLUS GOLD, can help enhance your memory, and improve focus and concentration.'
      },
      {
        id: 10,
        question: 'WHERE CAN WE BUY MEMO PLUS GOLD?',
        answer: 'MEMO PLUS GOLD is available at Mercury Drug and leading drugstores nationwide and online through Shopee and Lazada.'
      }
    ];
    this.selectedQuestion = this.questions[0];
  }

  questionChanged(event) {
    this.typing = true;

    const delay2 = of('').pipe(delay( 850 ));
    delay2.subscribe(s => { this.typing = false; });
  }
}

export class Question {
  id: number;
  question: string;
  answer: string;
}
