import { DynamicService } from './../../../services/dynamicengine.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  Input,
  Renderer2,
  AfterContentInit,
  TemplateRef,
  ViewChildren,
  ContentChild,
  ApplicationRef
} from '@angular/core';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.css', '../shared.component.css']
})
export class DynamicComponent implements OnInit, AfterContentInit {
  @ViewChildren('viewPort', {
    read: ViewContainerRef
  })
  viewContainerRef: ViewContainerRef;
  @ContentChild('gridLayout', {
    read: TemplateRef
  })
  oneContainerRef: TemplateRef<any>;
  @Input() dataSource: any;
  @Input() contentCount: number[];
  @Input() isGrid: boolean;

  constructor(
    private dys: DynamicService,
    private renderer: Renderer2,
    private compElementRef: ElementRef,
    private applicationRef: ApplicationRef
  ) {
    this.dys.renderer = this.renderer;
    this.dys.applicationRef = this.applicationRef;
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.dys.setNativeElement(this.compElementRef);
    this.dys.setParentEL(this.dataSource);
  }
}
