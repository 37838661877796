import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogent-about',
  templateUrl: './cogent-about.component.html',
  styleUrls: ['../cogentdb.component.css', './cogent-about.component.css']
})
export class CogentAboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
