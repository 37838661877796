import { MPGTipsComponent } from './memoplusgold/tips/tips.component';
import { appRoutes } from './tips.routing';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipsComponent } from './tips.component';
import { ArthridComponent } from './arthrid/arthrid.component';
import { CogentdbComponent } from './cogentdb/cogentdb.component';
import { MemoplusgoldComponent } from './memoplusgold/memoplusgold.component';
import { CogentDiabeatitComponent } from './cogentdb/cogent-diabeatit/cogent-diabeatit.component';
import { ContentOneComponent } from './cogentdb/cogent-diabeatit/content-one/content-one.component';
import { ContentTwoComponent } from './cogentdb/cogent-diabeatit/content-two/content-two.component';
import { ContentThreeComponent } from './cogentdb/cogent-diabeatit/content-three/content-three.component';
import { BitsComponent } from './memoplusgold/bits/bits.component';

@NgModule({
  imports: [
    CommonModule,
    appRoutes,
  ],
  declarations: [
    TipsComponent,
    ArthridComponent,
    CogentdbComponent,
    MemoplusgoldComponent,
    CogentDiabeatitComponent,
    ContentOneComponent,
    ContentTwoComponent,
    ContentThreeComponent,
    BitsComponent,
    MPGTipsComponent
  ]
})
export class TipsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TipsModule
    };
  }
}
