import { CounterFirebaseService } from './../../../services/counter.service';
import { ThemingService } from './../../../services/theming.service';
import { Footer } from './../../../models/footer.model';
import { FooterService } from './../../../services/footer.service';
import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { text } from '../../../../../node_modules/@angular/core/src/render3/instructions';
import { CounterModel } from '../../../models/counter.model';
import { JsonPipe } from '../../../../../node_modules/@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../shared.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild('footerBody', {read: ElementRef}) bodyRef: ElementRef;
  @ViewChild('footerText', {read: ElementRef}) textRef: ElementRef;
  footer: any;
  counter: any;
  once = 0;

  constructor(
    private fos: FooterService,
    private ths: ThemingService,
    private renderer: Renderer2,
    private counterService: CounterFirebaseService) {

    this.fos.footerValue.subscribe (data => {
      this.footer = {
        mpgfb: 'https://www.facebook.com/MemoPlusGoldPH/',
        cogentdbfb: 'https://www.facebook.com/CogentDb/',
        arthridfb: 'https://www.facebook.com/ArthridPH/',
        lazadaURL: 'davenben.lazada',
        shopeeURL: 'davenben.shopee',
        youtubeURL: 'davenben.youtube',
        product: 'davenben'
      };

      this.ths.GetThemeValue.subscribe(theme => {
        let bodyColor: string;
        let textColor: string;
        if (theme) {
          bodyColor = theme.footerColor;
          textColor = theme.footerFontColor;
        } else {
          bodyColor = 'white';
          textColor = 'black';
        }
        this.renderer.setStyle(this.bodyRef.nativeElement, 'background-color', bodyColor);
        this.renderer.setStyle(this.textRef.nativeElement, 'color', textColor);
      });
    });


    this.counterService.serviceList.subscribe(data => {
     this.counter = data[0];
     if(data[0]) {
       if(this.once == 0) {
         this.counterService.updateVisitorCount(data[0].docID, (data[0].count + 1));
         this.once++;
       }
     }
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
  }

}
