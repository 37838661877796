import { environment } from './../environments/environment';
import { TipsModule } from './components/tips/tips.module';
import { HomeComponent } from './components/home/home.component';
import { ProductsModule } from './components/products/products.module';
import { routes } from './app.route';
import { ContactComponent } from './components/contact/contact.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { AboutComponent } from './components/about/about.component';
import { SharedModule } from './components/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {
  AngularFirestoreModule,
} from 'angularfire2/firestore';

import { AngularFireModule } from 'angularfire2';
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    CampaignComponent,
    ContactComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    ProductsModule.forRoot(),
    SharedModule.forRoot(),
    routes,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
