import { RenderEngineService } from './../../../services/renderengine.service';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, AfterContentInit } from '@angular/core';
import { TouchSequence } from '../../../../../node_modules/@types/selenium-webdriver';

@Component({
  selector: 'app-renderengine',
  templateUrl: './renderengine.component.html',
  styleUrls: ['./renderengine.component.css', '../shared.component.css']
})
export class RenderengineComponent implements OnInit, AfterContentInit {

  @ViewChild('viewPort', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef;
  @Input() data: any;

  constructor(
    private res: RenderEngineService,
  ) {

  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.renderMe();
  }

  renderMe(containerRef?) {
    const container = containerRef ? containerRef : this.viewContainerRef;
    this.res.setRootViewContainerRef(container);
    this.res.renderComponent(this.data);
  }

}
