import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.css']
})
export class SharedComponent implements OnInit {

  @Input() displayElements: any[] = [];
  constructor() {
  }

  ngOnInit() {

  }

}
