import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogent-diabetes2',
  templateUrl: './cogent-diabetes2.component.html',
  styleUrls: ['../cogentdb.component.css', './cogent-diabetes2.component.css']
})
export class CogentDiabetes2Component implements OnInit {
  title = 'JABETIS';
  constructor() { }

  ngOnInit() {
  }

}
