import { Footer } from './../models/footer.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../../node_modules/rxjs';
@Injectable()
export class FooterService {
  private footer: any;
  private footerSubject: BehaviorSubject<any>;

  constructor() {
    this.footerSubject = new BehaviorSubject(this.footer);
  }

  set footerData(f: Footer) {
    this.footer = f;
    this.updateSubject();
  }

  get footerValue() {
    return this.footerSubject.asObservable();
  }

  private updateSubject() {
    this.footerSubject.next(this.footer);
  }
}
