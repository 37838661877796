import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {
  @ViewChild('bind', { read: ElementRef }) private bind: ElementRef;
  @Input() dataSource: any;

  bindTab: any;
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }
}
