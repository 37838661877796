import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-one',
  templateUrl: './content-one.component.html',
  styleUrls: ['./content-one.component.css']
})
export class ContentOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
