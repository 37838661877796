import { HomeComponent } from './components/home/home.component';
import { ProductsModule } from './components/products/products.module';
import { ContactComponent } from './components/contact/contact.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { AboutComponent } from './components/about/about.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '../../node_modules/@angular/compiler/src/core';
import { AppComponent } from './app.component';
import { TipsModule } from './components/tips/tips.module';

const router: Routes = [
  {
    path: '',
    component: AboutComponent,
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'campaign',
    component: CampaignComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'products',
    loadChildren: () => ProductsModule
  },
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router,  {useHash: true});
