import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cogent-clinical-studies',
  templateUrl: './cogent-clinical-studies.component.html',
  styleUrls: ['./cogent-clinical-studies.component.css']
})
export class CogentClinicalStudiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
