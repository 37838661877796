import { Card } from './../../../models/card.model';
import { Component, OnInit, Input, AfterContentInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() dataSource: any[];
  private card: Card;
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.dataSource.forEach(card => {
      this.card = card;
    });
  }
}
