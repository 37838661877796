import { CardComponent } from './../components/shared/card/card.component';
import { Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
@Injectable()
export class RenderEngineService {

  private rootContainerRef: ViewContainerRef;

  constructor(private factoryResolver: ComponentFactoryResolver) {}

  public setRootViewContainerRef(containerRef) {
    this.rootContainerRef = containerRef;
  }

  public renderComponent(element) {
    element.forEach(element => {
      const parameters = [element];
      const factory = this.factoryResolver.resolveComponentFactory(element.elementComponentType);
      const componentRef = this.rootContainerRef.createComponent(factory);

      switch(element.elementComponentType) {
        case CardComponent: {
          componentRef.instance['dataSource'] = element.childElements;
          break;
        }

        default: {
          Object.keys(parameters).forEach(inputItem => componentRef.instance['dataSource'] = parameters[inputItem]);
          break;
        }
      }


    });
  }
}
