import { ThemingService } from './../../services/theming.service';
import { FooterService } from './../../services/footer.service';
import { Theme } from './../../models/theme.model';
import { Footer } from './../../models/footer.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  footer: Footer = <Footer>{
    facebookURL: 'arthrid.facebook',
    instagramURL: 'arthrid.ig',
    twitterURL: 'arthrid.twitter',
    lazadaURL: 'arthrid.lazada',
    shopeeURL: 'arthrid.shopee',
    youtubeURL: 'arthrid.youtube',
    product: 'arthrid'
  };

  theme: Theme = <Theme> {
    navbarColor: '',
    navbarFontColor: 'white',
    footerColor: '#8cc63f',
    footerFontColor: 'white',
    sidePanelColor: '',
    sidePanelFontColor: ''
  };

  shop;

  constructor(
    private fos: FooterService,
    private ths: ThemingService) {
    this.fos.footerData = this.footer;
    this.ths.SetThemeValue = this.theme;
  }

  ngOnInit() {
    this.shop  = {
        mpg: {
        imgURL: './assets/products/shop/shop_cdb.png',
        shopeeURL: 'https://shopee.ph/winlodale',
        lazadaURL: 'https://www.lazada.com.ph/shop/memo-plus-gold-official-store'
      },
      arthrid: {
        imgURL: './assets/products/shop/shop_arthrid.png',
        shopeeURL: 'https://shopee.ph/arthridofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/arthrid-official-store'
      },
      cdb: {
        imgURL: './assets/products/shop/shop_mpg.png',
        shopeeURL: 'https://shopee.ph/cogentofficialstore',
        lazadaURL: 'https://www.lazada.com.ph/shop/cogent-db-official-store'
      }
    };
  }

}
