import { DynamicComponent } from './../components/shared/dynamic/dynamic.component';

export class IRenderContainerElement {
  elementComponentType?: any;
  elementName: string;
  elementTag: string;
  elementClass?: string[];
  widthPX?: number;
  widthPercent?: number;
  childElements?: any[];
  elementAttribute?: any;
}

export class RenderContainerElement extends IRenderContainerElement {

  private _isGrid?: boolean;
  get isGrid() {
    return this._isGrid;
  }

  set isGrid(value) {
    this._isGrid = value;
    this._isGrid === true ? this.isThisGrid() : this.elementTag = this.elementTag;
  }

  // tslint:disable-next-line:whitespace
  private isThisGrid? = () => {
    this.elementTag = 'div';
    this.elementClass ? this.elementClass.push('uk-grid') : this.elementClass = ['uk-grid'];
  }

  constructor(obj: RenderContainerElement) {
    super();
    if (obj) {
      this.elementComponentType = obj.elementComponentType ? obj.elementComponentType : DynamicComponent;
      this.elementName = obj.elementName ? obj.elementName : 'df';
      this.elementTag = obj.elementTag ? obj.elementTag : 'div';
      this.isGrid = obj.isGrid;
      this.elementAttribute = obj.elementAttribute ? obj.elementAttribute : undefined;
      this.childElements = obj.childElements ? obj.childElements : [];
      this.elementClass = obj.elementClass ? obj.elementClass : [];
    }
  }
}

export class ElementAttributes {
  src?: any;
  options?: any[];
  text?: string;
  href?: string;
}

