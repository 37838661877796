import { RouterModule } from '@angular/router';
import { ThemingService } from './../../services/theming.service';
import { RadialmenuComponent } from './radialmenu/radialmenu.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { FooterService } from './../../services/footer.service';
import { FooterComponent } from './footer/footer.component';
import { CardComponent } from './card/card.component';
import { DynamicService } from './../../services/dynamicengine.service';
import { RenderEngineService } from './../../services/renderengine.service';
import { RenderengineComponent } from './renderengine/renderengine.component';
import { DynamicComponent } from './dynamic/dynamic.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  AngularFirestore,
  AngularFirestoreCollection
} from 'angularfire2/firestore';
import { CounterFirebaseService } from '../../services/counter.service';
import { ShopbtnComponent } from './shopbtn/shopbtn.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    SharedComponent,
    RenderengineComponent,
    DynamicComponent,
    CardComponent,
    AccordionComponent,
    FooterComponent,
    RadialmenuComponent,
    ShopbtnComponent
  ],
  declarations: [
    SharedComponent,
    DynamicComponent,
    SwitcherComponent,
    RenderengineComponent,
    CardComponent,
    AccordionComponent,
    FooterComponent,
    RadialmenuComponent,
    ShopbtnComponent
  ],
  entryComponents: [
    DynamicComponent,
    RenderengineComponent,
    CardComponent,
    SwitcherComponent,
    AccordionComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        RenderEngineService,
        DynamicService,
        FooterService,
        ThemingService,
        AngularFirestore,
        CounterFirebaseService
      ]
    };
  }
}
